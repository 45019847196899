@import "colors";

.chantier {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $text;
    .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
            color: $text;
            margin: 1em 0 0;
        }
        hr {
            width: calc(100% - 4em);
            height: 5px;
            background: $primary;
            border: none;
            margin: 0.5em 0 2em;
        }
    }
    .files-zone {
        margin-top: 5em;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}
.personnels-container {
    .list {
        max-height: 150px;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        margin: 0 auto;
    }
}
.perso {
    display: flex;
    position: relative;
    justify-content: space-between;
    white-space: nowrap;
    margin: 0;
    padding: 0 6em;
    border-bottom: 1px solid #555555;
    label {
        padding: 0.5em 0;
        display: flex;
        align-items: baseline;
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 7px;
        left: 3em;
        height: 20px;
        width: 20px;
        background-color: #eee;
    }

    /* On mouse-over, add a grey background color */
    :hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark {
        background-color: $primary;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

