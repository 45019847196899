@import "colors";

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    left: 0;
    right: 0;
    &__logo {
        display: flex;
        align-items: center;
        width: 140px;
        img {
            width: 100%;
        }
        @media screen and (max-width: 500px) {
            width: 85px;
        }
    }
    background-color: #ffffff0b;
    box-shadow: 0 2px 10px 0 #00000080;
    padding: 0 50px;
    color: $text;
    @media screen and (max-width: 500px) {
        padding: 0 20px;
    }
    .logadmin {
        button {
            background: none;
            border: none;
            padding: 0;
            &:hover,
            &:focus {
                background: none;
            }
        }
    }
    button {
        background: $tertiary;
        border: #000000cb solid 1px;
        padding: 0 1em ;
        border-radius: 10px;
        color: $primary;
        transition: 0.2s;
        cursor: pointer;
        &:hover,
        &:focus {
            background: #1a1c21;
            transition: 0.2s;
        }
    }
    .nav {
        display: flex;
        height: 50px;
        align-items: center;
        gap: 1em;
        &__link {
            text-decoration: none;
            color: $primary;
            font-size: 1.2em;
            margin: 0 1em;
            transition: 0.2s;
            &:hover,
            &:focus {
                color: $hover;
                transition: 0.2s;
                cursor: pointer;
            }
            &.active {
                color: $hover;
                cursor: default;
            }
        }
    }
}