@import "colors";

.description {
    display: flex;
    width: 100%;
    gap: 20px;
    div {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: #303035DD;
        border: black solid 1px;
        box-shadow: 0 10px 10px -3px #00000080 ;
        padding: 0.2em 0.5em;
        border-radius: 10px;
        h3 {
            color: white;
            margin: 0.25em;
        }
        span {
            font-weight: normal;
        }
    }
}