.reporting, .bdd, .bsd {
  max-width: 385px;
}
.reporting h2, .bdd h2, .bsd h2 {
  margin: 0;
}
.reporting .title, .bdd .title, .bsd .title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.reporting .title h1, .bdd .title h1, .bsd .title h1 {
  color: #F2F2F2;
  margin: 1em 0 0;
}
.reporting .title hr, .bdd .title hr, .bsd .title hr {
  width: calc(100% - 4em);
  height: 5px;
  background: #CC9900;
  border: none;
  margin: 0.5em 0 2em;
}
.reporting-container, .bdd-container, .bsd-container {
  min-height: 150px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
}
.reporting-container .file, .bdd-container .file, .bsd-container .file {
  display: flex;
  flex-flow: wrap;
  gap: 15px;
}

.administratif .title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.administratif .title h1 {
  color: #F2F2F2;
  margin: 1em 0 0;
}
.administratif .title hr {
  width: calc(100% - 4em);
  height: 5px;
  background: #CC9900;
  border: none;
  margin: 0.5em 0 2em;
}
.administratif-container {
  min-height: 150px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
}
.administratif-container .file {
  display: flex;
  flex-flow: wrap;
  gap: 15px;
}

.modal-file {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}
.modal-file.open {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-file .modal-close {
  top: 25px;
  right: 25px;
}
.modal-file .file-container {
  display: flex;
  height: 95%;
  justify-content: center;
}
.modal-file .file-container .react-pdf__Document {
  overflow: auto;
}
.modal-file .file-container .react-pdf__Document .react-pdf__Page {
  transform: scale(1);
}
.modal-file .file-container:hover .page-selector {
  opacity: 1;
  transition: 0.2s;
}
.modal-file .file-container .page-selector {
  display: flex;
  align-items: center;
  opacity: 0;
  transition: 0.2s;
  position: absolute;
  bottom: 30px;
  background: #303035;
  font-size: 20px;
  color: white;
  gap: 15px;
}
.modal-file .file-container .page-selector button {
  background: #303035;
  padding: 10px;
  width: 40px;
  border: none;
  font-size: 20px;
  color: white;
}
.modal-file .file-container .page-selector button:hover {
  background: #CC9900;
  color: #E7C768;
}
.modal-file .close {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  top: 15px;
  right: 15px;
  background: #22252b;
  border: rgba(0, 0, 0, 0.7960784314) solid 1px;
  border-radius: 10px;
  fill: #CC9900;
  transition: 0.2s;
  cursor: pointer;
}
.modal-file .close:hover, .modal-file .close:focus {
  background: #1a1c21;
  transition: 0.2s;
}/*# sourceMappingURL=Files.css.map */