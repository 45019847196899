.description {
  display: flex;
  width: 100%;
  gap: 20px;
}
.description div {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: rgba(48, 48, 53, 0.8666666667);
  border: black solid 1px;
  box-shadow: 0 10px 10px -3px rgba(0, 0, 0, 0.5019607843);
  padding: 0.2em 0.5em;
  border-radius: 10px;
}
.description div h3 {
  color: white;
  margin: 0.25em;
}
.description div span {
  font-weight: normal;
}/*# sourceMappingURL=Description.css.map */