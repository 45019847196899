.modal {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}
.modal .modal-content {
  position: relative;
  text-align: center;
  background-color: #22252b;
  margin: 15% auto;
  padding: 20px;
  width: 300px;
  border: black solid 1px;
  box-shadow: 0 10px 10px -3px rgba(0, 0, 0, 0.5019607843);
  padding: 0.2em 0.5em;
  border-radius: 10px;
}
.modal .modal-content input {
  background: #303035;
}
.modal-close {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  top: -15px;
  right: -15px;
  background: #22252b;
  border: rgba(0, 0, 0, 0.7960784314) solid 1px;
  border-radius: 10px;
  fill: #CC9900;
  transition: 0.2s;
}
.modal-close:hover, .modal-close:focus {
  background: #1a1c21;
  cursor: pointer;
  transition: 0.2s;
}

.modal.open {
  display: block;
}/*# sourceMappingURL=Login.css.map */