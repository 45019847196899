.chantier {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #F2F2F2;
}
.chantier .title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chantier .title h1 {
  color: #F2F2F2;
  margin: 1em 0 0;
}
.chantier .title hr {
  width: calc(100% - 4em);
  height: 5px;
  background: #CC9900;
  border: none;
  margin: 0.5em 0 2em;
}
.chantier .files-zone {
  margin-top: 5em;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.personnels-container .list {
  max-height: 150px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin: 0 auto;
}

.perso {
  display: flex;
  position: relative;
  justify-content: space-between;
  white-space: nowrap;
  margin: 0;
  padding: 0 6em;
  border-bottom: 1px solid #555555;
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}
.perso label {
  padding: 0.5em 0;
  display: flex;
  align-items: baseline;
}
.perso input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.perso .checkmark {
  position: absolute;
  top: 7px;
  left: 3em;
  height: 20px;
  width: 20px;
  background-color: #eee;
}
.perso :hover input ~ .checkmark {
  background-color: #ccc;
}
.perso input:checked ~ .checkmark {
  background-color: #CC9900;
}
.perso .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.perso input:checked ~ .checkmark:after {
  display: block;
}
.perso .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}/*# sourceMappingURL=Chantier.css.map */