body {
  background: url("/src/assets/img/Fond3.png") no-repeat fixed center !important;
  background-size: cover !important;
}

.accueil {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0 100px;
}
.accueil__logo {
  width: 400px;
}
@media screen and (max-width: 450px) {
  .accueil__logo {
    width: 275px;
  }
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form__input {
  width: 150px;
  height: 30px;
  margin: 10px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  font-size: 20px;
  text-align: center;
  background-color: #303035;
  color: #F2F2F2;
}
.form__button {
  background: none;
  padding: 0;
  border: none;
  height: 40px;
  width: 40px;
  cursor: pointer;
}
.form__button svg {
  fill: #CC9900;
  transition: linear 0.1s;
}
.form__button svg:hover {
  fill: #e7c768;
  transition: linear 0.1s;
}/*# sourceMappingURL=Accueil.css.map */