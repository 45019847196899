.planning {
  width: 100%;
  min-height: 350px;
  color: #F2F2F2;
}
.planning-table {
  width: 100%;
}
.planning-container {
  width: 90%;
  margin: 0 auto;
}
.planning-content {
  overflow-x: auto;
  box-shadow: 0 10px 10px -3px rgba(0, 0, 0, 0.5019607843);
}
.planning table {
  border-collapse: collapse;
  cursor: default;
  border: 2px solid #555555;
}
.planning button {
  cursor: pointer;
}
.planning td {
  text-align: center;
  height: 10px;
}
.planning tr {
  border: none;
  border-bottom: #555555 solid 1px;
}
.planning .jour {
  height: 50px;
  background-color: #303035;
  border: 1px solid #555555;
  padding: 0 0.4em;
}
.planning .jour:hover {
  background-color: #1a1c21;
  cursor: default;
}
.planning .jour.border {
  border-left: 4px double #555555;
}
.planning .jour:hover .pointage {
  display: block;
  z-index: 100;
}
.planning .task {
  border: 1px solid #555555;
  background-color: #303035;
}
.planning .prevu {
  background-color: #777777;
}
.planning .late {
  background-color: red;
}
.planning .intime {
  background-color: #00ff00;
}
.planning .today {
  background-color: #CC9900;
  font-weight: bolder;
}
.planning .today:hover {
  background-color: #795f11;
  cursor: default;
}
.planning #mois {
  border: 1px solid #777777;
  font-weight: bolder;
  background-color: #22252b;
  min-width: 120px;
  max-width: 120px;
}
.planning #mois.curMonth {
  background-color: #CC9900;
}

.planningAction {
  position: absolute;
  left: -150px;
}

.abs {
  position: relative;
}/*# sourceMappingURL=Planning.css.map */