@import "colors";
.supp_doc{
    color: red !important;
    position: absolute;
    margin: 0 auto;
    top: 10px;
    z-index: 100;

}
.lightbox {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    &.show {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &-content {
        display: flex;
        max-width: 70%;
        max-height: 90vh;
        margin: 5vh 0;
    }
    &-body {
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .close {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 65px;
        height: 65px;
        top: 15px;
        right: 15px;
        background: $background;
        border: #000000cb solid 1px;
        border-radius: 10px;
        fill: $primary;
        transition: 0.2s;
        cursor: pointer;
        &:hover,
        &:focus {
            background: #1a1c21;
            transition: 0.2s;
        }
    }
    .left-arrow {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 65px;
        height: 65px;
        top: 45%;
        left: 100px;
        background: $background;
        border: #000000cb solid 1px;
        border-radius: 10px;
        transition: 0.2s;
        cursor: pointer;
        &:hover,
        &:focus {
            background: #1a1c21;
            transition: 0.2s;
        }
        svg {
            fill: $primary;
            width: 30px;
            height: 30px;
        }
    }
    .right-arrow {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 65px;
        height: 65px;
        top: 45%;
        right: 100px;
        background: $background;
        border: #000000cb solid 1px;
        border-radius: 10px;
        transition: 0.2s;
        cursor: pointer;
        &:hover,
        &:focus {
            background: #1a1c21;
            transition: 0.2s;
        }
        svg {
            fill: $primary;
            width: 30px;
            height: 30px;
        }
    }
}