.pointage {
  display: none;
  position: absolute;
  top: -90px;
}
.pointage__info {
  text-align: left;
  padding: 15px;
  background-color: #22252b;
  min-height: 60px;
  min-width: 120px;
}/*# sourceMappingURL=Pointage.css.map */