@import "colors";

.reporting, .bdd, .bsd {
    max-width: 385px;
    h2 {
        margin: 0;
    }
    .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
        color: $text;
        margin: 1em 0 0;
        }
        hr  {
            width: calc(100% - 4em);
            height: 5px;
            background: $primary;
            border: none;
            margin: 0.5em 0 2em;
        }
    }
    &-container {
        min-height: 150px;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 1rem;
        .file {
            display: flex;
            flex-flow: wrap;
            gap: 15px;
        }
    }
}

.administratif {
    .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
        color: $text;
        margin: 1em 0 0;
        }
        hr  {
            width: calc(100% - 4em);
            height: 5px;
            background: $primary;
            border: none;
            margin: 0.5em 0 2em;
        }
    }
    &-container {
        min-height: 150px;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 1rem;
        .file {
            display: flex;
            flex-flow: wrap;
            gap: 15px;
        }
    }
}

.modal-file {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000099;
    z-index: 100;
    &.open {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .modal-close {
        top: 25px;
        right: 25px;
    }
    .file-container {
        display: flex;
        height: 95%;
        justify-content: center;
        .react-pdf__Document {
            overflow: auto;
            .react-pdf__Page {
                transform: scale(1);
            }
        }
        &:hover {
            .page-selector {
                opacity: 1;
                transition: 0.2s;
            }
        }
        .page-selector {
            display: flex;
            align-items: center;
            opacity: 0;
            transition: 0.2s;
            position: absolute;
            bottom: 30px;
            background: $tertiary;
            font-size: 20px;
            color: white;
            gap: 15px;
            button {
                background: $tertiary;
                padding: 10px;
                width: 40px;
                border: none;
                font-size: 20px;
                color: white;
                &:hover {
                    background: $primary;
                    color: $secondary;
                }
            }
        }
    }
    .close {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 65px;
        height: 65px;
        top: 15px;
        right: 15px;
        background: $background;
        border: #000000cb solid 1px;
        border-radius: 10px;
        fill: $primary;
        transition: 0.2s;
        cursor: pointer;
        &:hover,
        &:focus {
            background: #1a1c21;
            transition: 0.2s;
        }
    }
}

