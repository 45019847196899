.supp_doc {
  color: red !important;
  position: absolute;
  margin: 0 auto;
  top: 10px;
  z-index: 100;
}

.lightbox {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.lightbox.show {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lightbox-content {
  display: flex;
  max-width: 70%;
  max-height: 90vh;
  margin: 5vh 0;
}
.lightbox-body img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.lightbox .close {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  top: 15px;
  right: 15px;
  background: #22252b;
  border: rgba(0, 0, 0, 0.7960784314) solid 1px;
  border-radius: 10px;
  fill: #CC9900;
  transition: 0.2s;
  cursor: pointer;
}
.lightbox .close:hover, .lightbox .close:focus {
  background: #1a1c21;
  transition: 0.2s;
}
.lightbox .left-arrow {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  top: 45%;
  left: 100px;
  background: #22252b;
  border: rgba(0, 0, 0, 0.7960784314) solid 1px;
  border-radius: 10px;
  transition: 0.2s;
  cursor: pointer;
}
.lightbox .left-arrow:hover, .lightbox .left-arrow:focus {
  background: #1a1c21;
  transition: 0.2s;
}
.lightbox .left-arrow svg {
  fill: #CC9900;
  width: 30px;
  height: 30px;
}
.lightbox .right-arrow {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  top: 45%;
  right: 100px;
  background: #22252b;
  border: rgba(0, 0, 0, 0.7960784314) solid 1px;
  border-radius: 10px;
  transition: 0.2s;
  cursor: pointer;
}
.lightbox .right-arrow:hover, .lightbox .right-arrow:focus {
  background: #1a1c21;
  transition: 0.2s;
}
.lightbox .right-arrow svg {
  fill: #CC9900;
  width: 30px;
  height: 30px;
}/*# sourceMappingURL=LightBox.css.map */