body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #22252b;
  min-height: 95vh;
}
body.modal-open {
  overflow: hidden;
}

main {
  padding: 0 100px;
}

section {
  width: 100%;
}
section h2 {
  align-self: flex-start;
  color: #F2F2F2;
  margin: 2em 0 0;
}
section .subt {
  align-self: flex-start;
  width: 75px;
  height: 5px;
  background: #CC9900;
  border: none;
  margin: 0.5em 0 2em;
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}/*# sourceMappingURL=index.css.map */