@import "colors";

.planning {
    width: 100%;
    min-height: 350px;
    &-table {
        width: 100%;
    }
    color: $text;

    &-container {
        width: 90%;
        margin: 0 auto;
    }

    &-content {
        overflow-x: auto;
        box-shadow: 0 10px 10px -3px #00000080;
    }

    table { 
        border-collapse: collapse; 
        cursor: default;
        border: 2px solid #555555;
    }

    button {
        cursor: pointer;
    }

    td {
        text-align: center;
        height: 10px;
    }

    tr {
        border: none;
        border-bottom: #555555 solid 1px;
    }

    .jour {
        height: 50px;
        background-color: $tertiary;
        border: 1px solid #555555;
        padding: 0 0.4em;
        &:hover {
            background-color: #1a1c21;
            cursor: default;
        }
        &.border {
            border-left: 4px double #555555;
        }
        &:hover .pointage {
            display: block;
            z-index: 100;
        }
    }

    .task {
        border: 1px solid #555555;
        background-color: $tertiary;
    }

    .prevu {
        background-color: #777777;
    }

    .late {
        background-color: red;
    }

    .intime {
        background-color: #00ff00;
    }

    .today {
        background-color: $primary;
        font-weight: bolder;
        &:hover {
            background-color: #795f11;
            cursor: default;
        }
    }
    #mois {
        border: 1px solid #777777;
        font-weight: bolder;
        background-color: $background;
        min-width: 120px;
        max-width: 120px;
        &.curMonth {
            background-color: $primary;
        }
    }
}

.planningAction {
    position: absolute;
    left : -150px;
}
.abs {
    position: relative;
}