.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.0431372549);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5019607843);
  padding: 0 50px;
  color: #F2F2F2;
}
.header__logo {
  display: flex;
  align-items: center;
  width: 140px;
}
.header__logo img {
  width: 100%;
}
@media screen and (max-width: 500px) {
  .header__logo {
    width: 85px;
  }
}
@media screen and (max-width: 500px) {
  .header {
    padding: 0 20px;
  }
}
.header .logadmin button {
  background: none;
  border: none;
  padding: 0;
}
.header .logadmin button:hover, .header .logadmin button:focus {
  background: none;
}
.header button {
  background: #303035;
  border: rgba(0, 0, 0, 0.7960784314) solid 1px;
  padding: 0 1em;
  border-radius: 10px;
  color: #CC9900;
  transition: 0.2s;
  cursor: pointer;
}
.header button:hover, .header button:focus {
  background: #1a1c21;
  transition: 0.2s;
}
.header .nav {
  display: flex;
  height: 50px;
  align-items: center;
  gap: 1em;
}
.header .nav__link {
  text-decoration: none;
  color: #CC9900;
  font-size: 1.2em;
  margin: 0 1em;
  transition: 0.2s;
}
.header .nav__link:hover, .header .nav__link:focus {
  color: #8d6a00;
  transition: 0.2s;
  cursor: pointer;
}
.header .nav__link.active {
  color: #8d6a00;
  cursor: default;
}/*# sourceMappingURL=Header.css.map */