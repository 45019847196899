@import "colors";

.gallery-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    min-height: 150px;
}

.weeksBtn {
    background: $tertiary;
    border: none;
    color: $primary;
    font-size: 1em;
    font-weight: 600;
    border-radius: 10px;
    border: black solid 1px;
    box-shadow: 0 10px 10px -3px #00000080;
    padding: 0em 1em;
    cursor: pointer;
    transition: 0.2s;
    &:hover,
    &:focus {
        background: #1f1f22;
        transition: 0.2s;
    }
}

.modal-gallery {
    display: none;
    &-content {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: auto;
        background: $background;
        border: black solid 1px;
        box-shadow: 0 10px 10px -3px #00000080;
        width: 80%;
        max-width: 700px;
        .close {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            top: -15px;
            right: -15px;
            background: $background;
            border: #000000cb solid 1px;
            border-radius: 10px;
            fill: $primary;
            transition: 0.2s;
            cursor: pointer;
            &:hover,
            &:focus {
                background: #1a1c21;
                transition: 0.2s;
            }
        }
    }
    &-header {
        padding: 15px 2em;
        border-bottom: 1px solid #00000090;
        h2 {
            margin: 0;
        }
    }
    &-body {
        display: flex;
        align-items: center;
        padding: 2em;
        gap: 1.1em;
        justify-content: flex-start;
        flex-flow: wrap;
    }
    &.show {
        display: block;
        position: fixed;
        z-index: 1;
        padding-top: 100px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.6);
    }
}

.gallery-image {
    width: 75px;
    height: 75px;
    
    img {
        width: 75px;
        height: 75px; 
        object-fit: cover;
        border-radius: 5px;
        transition: 0.2s;
        color: $text;
        border: 1px solid #00000080;
        cursor: pointer;
        &:hover {
            transform: scale(1.2);
            transition: 0.2s;
        }
    }
}
