@import "colors";

body {
    background: url("/src/assets/img/Fond3.png") no-repeat fixed center !important;
    background-size: cover !important;
}

.accueil {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__logo {
        width: 400px;
        @media screen and (max-width: 450px) {
            width: 275px;
        }
    }
    margin: 50px 0 100px;
}

.form {
    display: flex;
    justify-content: center;
    align-items: center;
    &__input {
        width: 150px;
        height: 30px;
        margin: 10px;
        border: 1px solid black;
        border-radius: 5px;
        padding: 10px;
        font-size: 20px;
        text-align: center;
        background-color: #303035;
        color: $text;
    }
    &__button {
        background: none;
        padding: 0;
        border: none;
        height: 40px;
        width: 40px;
        cursor: pointer;
        svg {
            fill : $primary;
            transition: linear 0.1s;
            &:hover {
                fill: #e7c768;
                transition: linear 0.1s;
            }
        }
    }
}