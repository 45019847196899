@import "colors";

.modal {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000099;
    .modal-content {
        position: relative;
        text-align: center;
        background-color: $background;
        margin: 15% auto;
        padding: 20px;
        width: 300px;
        border: black solid 1px;
        box-shadow: 0 10px 10px -3px #00000080;
        padding: 0.2em 0.5em;
        border-radius: 10px;
        input {
            background: $tertiary;
        }
    }
    &-close {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        top: -15px;
        right: -15px;
        background: $background;
        border: #000000cb solid 1px;
        border-radius: 10px;
        fill: $primary;
        transition: 0.2s;
        &:hover,
        &:focus {
            background: #1a1c21;
            cursor: pointer;
            transition: 0.2s;
        }
    }
}

.modal.open {
    display: block;
}