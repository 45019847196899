@import "colors";

body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $background;
    min-height: 95vh;

    &.modal-open {
        overflow: hidden;
    }
}

main {
    padding: 0 100px;
}

section {
    width: 100%;
    h2 {
        align-self: flex-start;
        color: $text;
        margin: 2em 0 0;
    }
    .subt {
        align-self: flex-start;
        width: 75px;
        height: 5px;
        background: $primary;
        border: none;
        margin: 0.5em 0 2em;
    }
}

.d-none {
    display: none;
}

.d-flex {
    display: flex;
}