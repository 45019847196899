body:has(.accueil) {
  background: url("/src/assets/img/Fond3.png") no-repeat fixed center !important;
  background-size: cover !important;
  color: #e6e6e6;
}

form input {
  color: #F2F2F2;
}

.accueil__container {
  display: flex;
  gap: 5rem;
}
.accueil__container .listeChantier, .accueil__container .listePersonnel {
  flex: 1 1 50%;
}

td, th {
  padding-inline: 10px;
  white-space: nowrap;
}/*# sourceMappingURL=Admin.css.map */