.gallery-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  min-height: 150px;
}

.weeksBtn {
  background: #303035;
  border: none;
  color: #CC9900;
  font-size: 1em;
  font-weight: 600;
  border-radius: 10px;
  border: black solid 1px;
  box-shadow: 0 10px 10px -3px rgba(0, 0, 0, 0.5019607843);
  padding: 0em 1em;
  cursor: pointer;
  transition: 0.2s;
}
.weeksBtn:hover, .weeksBtn:focus {
  background: #1f1f22;
  transition: 0.2s;
}

.modal-gallery {
  display: none;
}
.modal-gallery-content {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  background: #22252b;
  border: black solid 1px;
  box-shadow: 0 10px 10px -3px rgba(0, 0, 0, 0.5019607843);
  width: 80%;
  max-width: 700px;
}
.modal-gallery-content .close {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  top: -15px;
  right: -15px;
  background: #22252b;
  border: rgba(0, 0, 0, 0.7960784314) solid 1px;
  border-radius: 10px;
  fill: #CC9900;
  transition: 0.2s;
  cursor: pointer;
}
.modal-gallery-content .close:hover, .modal-gallery-content .close:focus {
  background: #1a1c21;
  transition: 0.2s;
}
.modal-gallery-header {
  padding: 15px 2em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5647058824);
}
.modal-gallery-header h2 {
  margin: 0;
}
.modal-gallery-body {
  display: flex;
  align-items: center;
  padding: 2em;
  gap: 1.1em;
  justify-content: flex-start;
  flex-flow: wrap;
}
.modal-gallery.show {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
}

.gallery-image {
  width: 75px;
  height: 75px;
}
.gallery-image img {
  width: 75px;
  height: 75px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 5px;
  transition: 0.2s;
  color: #F2F2F2;
  border: 1px solid rgba(0, 0, 0, 0.5019607843);
  cursor: pointer;
}
.gallery-image img:hover {
  transform: scale(1.2);
  transition: 0.2s;
}/*# sourceMappingURL=Gallery.css.map */