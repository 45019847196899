@import "colors";

.pointage {
    display: none;
    position: absolute;
    top: -90px;
    &__info {
        text-align: left;
        padding: 15px;
        background-color: $background;
        min-height: 60px;
        min-width: 120px;
    }
}